@import '../../vars';

.subnav {
  height: $_subnav-height;
  padding-top: $_subnav-padding-y;
  padding-bottom: $_subnav-padding-y;

  overflow-x: auto;
  overflow-y: hidden;

  .nav {
    display: block;
    white-space: nowrap;
  }

  .nav-item {
    display: inline-block;

    &:first-child {
      margin-left: -1rem;
    }

    &:last-child {
      margin-right: -1rem;
    }

    &.active {
      .nav-link {
        font-weight: bold;
      }
    }
  }

  .nav-link {
    height: #{$_subnav-height - ($_subnav-padding-y * 2)};
    line-height: #{$_subnav-height - ($_subnav-padding-y * 2)};
    padding-top: 0;
    padding-bottom: 0;
    white-space: nowrap;
  }
}

// Theme specifics (not configurable via bootstrap overrides)
.subnav {
  background-color: #fff;
  .nav-link {
    color: $picasso-blue;

    &:hover {
      color: $main-navigation-hover-color;
    }
  }
}

.page-artist {
  .subnav {
    .nav {
      text-align: center;
    }
  }
}

@include media-breakpoint-up(lg) {
  .page-artist {
    .subnav {
      .nav {
        text-align: left;
      }
    }
  }
}
