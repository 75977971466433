@import '../../vars';

@mixin card-ribbon($color, $background, $shadow) {
  position: absolute;
  right: -8px;
  top: 19px;
  padding: 0 8px;
  font-size: 13px;
  font-weight: bold;
  color: $color;
  background: $background;
  text-transform: uppercase;
  line-height: 32px;
  height: 30px;
  box-shadow: -4px 3px 4px 0px rgba(0, 0, 0, 0.12),
    -2px 11px 17px -8px rgba(0, 0, 0, 0.3),
    -10px 6px 21px -1px rgba(0, 0, 0, 0.14);
  pointer-events: none;

  &::before {
    content: '';
    position: absolute;
    font-size: 0;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 7px solid $shadow;
    top: -5px;
    right: 0;
  }

  &::after {
    content: '';
    position: absolute;
    font-size: 0;
    width: 0;
    height: 0;
    border-radius: 2px 0 0 2px;
    top: 0;
    left: -10px;
    border-top: 15px solid $background;
    border-bottom: 15px solid $background;
    border-left: 10px solid transparent;
  }
}

.card-ribbon {
  &.green {
    $color: #02103c;
    $background: #7ce0d3;
    $shadow: #2f5d57;
    @include card-ribbon($color, $background, $shadow);
  }

  &.red {
    $color: #fff;
    $background: $rothko-red;
    $shadow: #830b25;
    @include card-ribbon($color, $background, $shadow);
  }
}
