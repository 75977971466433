@import '../../vars';

.lazy-image {
  position: relative;
  margin: 0;
  overflow: hidden;

  img {
    animation: fadeIn 1.5s;
  }
}
