@import '../../vars';

.filter-toggler {
  padding: 1rem 0;
  cursor: pointer;
  display: flex;
  align-items: center;

  h5 {
    flex-grow: 1;
  }
}

.filter-content {
  display: none;

  &.show {
    display: block;
  }
}

.filter-section {
  .form-check-label {
    cursor: pointer;
  }
}

// Theme specifics (not configurable via bootstrap overrides)
.filter-section {
  border-top: 1px solid $whistler-grey-50;

  &:last-of-type {
    border-bottom: 1px solid $whistler-grey-50;
  }

  .form-check {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
}
.filter-toggler {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.filter-content {
  padding-bottom: 1.25rem;
}
