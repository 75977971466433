@import '../../src/vars';

.page-artist {
  /// @todo Remove modals from header and change z-index to sticky
  header {
    position: sticky;
    z-index: 1041;

    @include media-breakpoint-up(sm) {
      top: 0;
    }
  }

  .salebar {
    text-align: center;

    a {
      display: inline-block;
      padding: 10px 0;
      margin: 10px 0;
      text-decoration: none;
    }

    .bi {
      margin-top: -4px;
    }
  }

  .sortbar-inner {
    display: flex;
    height: $_sortbar-height;
    padding-top: $_sortbar-padding-y;
    padding-bottom: $_sortbar-padding-y;
  }

  .sortbar-count {
    flex-grow: 1;
    line-height: #{$_sortbar-height - ($_sortbar-padding-y * 2)};
  }

  .sortbar-dropdown {
    flex-grow: 0;

    .dropdown-select {
      margin-top: #{($_sortbar-height - ($_sortbar-padding-y * 2) - 50px) / 2};
      margin-bottom: #{($_sortbar-height - ($_sortbar-padding-y * 2) - 50px) / 2};

      background-color: transparent;
      width: 145px;
    }

    .dropdown-select button {
      background-color: transparent;
    }

    .dropdown-select .form-control {
      border: none;
    }
  }

  .filters-col {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .page-artist {
    .artwork-by-artist {
      height: $_artwork-by-artist-height;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .filters-col {
      display: block;
    }
  }
}

// Theme specifics (not configurable via bootstrap overrides)
.artwork-by-artist {
  display: none;
}

@include media-breakpoint-up(md) {
  .artwork-by-artist {
    display: block;
    text-align: center;
  }
}
