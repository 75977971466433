@import '../../vars';
$s: 1.25rem;

.masonry-gallery {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: stretch;
  grid-gap: $s;
  padding: 5px;
  grid-template-rows: masonry;

  &.not-supported {
    grid-template-rows: auto;
  }

  > *,
  > .product-card {
    width: initial;
    height: fit-content;
    overflow: hidden;
  }

  @include media-breakpoint-up(sm) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
