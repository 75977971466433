@import '../../../vars';

.product-card {
  width: 270px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14);
  position: relative;

  &:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.14);
  }

  figure {
    border-radius: 4px 4px 0 0;
    overflow: hidden;
  }

  .prints-promo {
    background: $monet-green;
    padding: 3px 10px;
    position: relative;
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &--body {
    position: relative;

    h6,
    h5 {
      color: $picasso-blue-60;
    }

    > section {
      padding: 10px;
    }

    .product-tag-sold::after {
      content: '';
      display: inline-block;
      margin-left: 0.5em;
      width: 0.875rem;
      height: 0.875rem;
      border-radius: 50%;
      background-color: $rothko-red;
    }
  }

  &__buttons {
    position: absolute;
    bottom: 10px;
    right: 10px;

    & > *:first-child {
      margin: 0;
    }
  }

  .btn-action {
    width: 31px;
    height: 31px;
    border: 1px solid #ebe9e5;
    margin-left: 5px;

    svg {
      stroke: none;
      height: 15px;
      fill: $picasso-blue;
      margin: 0 0 1px;
    }

    &:hover,
    &:not(:disabled).active {
      border-color: #ebe9e5;

      svg {
        fill: $rothko-red;
      }
    }
  }

  &--small {
    width: 176px;
    border-radius: 8px;

    figure {
      border-radius: 8px 8px 0 0;
      overflow: hidden;

      img {
        height: 176px;
        object-fit: cover;
      }
    }
  }

  &--attraqt {
    figure {
      height: 250px;
      max-height: 250px;
      border-radius: 8px 8px 0 0;
      overflow: hidden;
    }
  }
}
