@import '../../vars';

$_avatar-size: 147px;

.artist {
  background-color: #d7d2cb;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .artist-buttons .counter {
    display: none;
  }

  .artist-buttons button {
    width: 100%;
    margin-top: $spacer-m;
  }

  .artist-avatar {
    width: 147px;
    height: 147px;
  }

  h2 {
    margin: 1.25rem 0;
  }

  .btn-tertiary {
    margin-bottom: 1.25rem;
  }

  .artist-rating {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .artist-cover {
    display: none;
  }

  .artist-left {
    display: flex;
    flex-direction: column;

    > p:first-of-type {
      margin-top: 1.25rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .artist {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    .artist-buttons button {
      width: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .artist-cover {
      display: block;
      height: 120px;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      margin-bottom: -#{$_avatar-size / 2};
      margin-top: -2.5rem;
    }

    .artist-left {
      align-items: center;
    }
  }
}

@include media-breakpoint-up(lg) {
  .artist {
    position: relative;

    & > .container {
      position: relative;
    }
    .artist-cover,
    .artist-left {
      width: 50%;
    }

    .artist-cover {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      height: auto;
    }

    .artist-buttons {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-bottom: -99px;
      margin-top: 0;
      display: flex;
      flex-direction: row-reverse;

      & > button {
        margin: 0 15px 0 0;
      }

      .counter {
        display: inline;
      }
    }
  }
}
